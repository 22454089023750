@font-face {
	font-family: 'SourceSansPro';
	src:	url('SourceSansPro-Bold.eot');
	src:	url('SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
				url('SourceSansPro-Bold.woff') format('woff'),
				url('SourceSansPro-Bold.ttf') format('truetype'),
				url('SourceSansPro-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'SourceSansPro';
	src:	url('SourceSansPro-Semibold.eot');
	src:	url('SourceSansPro-Semibold.eot?#iefix') format('embedded-opentype'),
				url('SourceSansPro-Semibold.woff') format('woff'),
				url('SourceSansPro-Semibold.ttf') format('truetype'),
				url('SourceSansPro-Semibold.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'SourceSansPro';
	src:	url('SourceSansPro-Regular.eot');
	src:	url('SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
				url('SourceSansPro-Regular.woff') format('woff'),
				url('SourceSansPro-Regular.ttf') format('truetype'),
				url('SourceSansPro-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'FVAlmelo';
	src: url('fv_almelo-webfont.eot');
	src: url('fv_almelo-webfont.eot?#iefix') format('embedded-opentype'),
			 url('fv_almelo-webfont.woff') format('woff'),
			 url('fv_almelo-webfont.ttf') format('truetype'),
			 url('fv_almelo-webfont.svg#FVAlmeloRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}

